@import 'cropperjs/dist/cropper.css';
@import 'tom-select/dist/css/tom-select.default.css';

@tailwind base;
@tailwind components;

@import 'components/range-slider';


@tailwind utilities;

/* Alpine JS fix for flikkering */
[x-cloak] {
  display: none !important;
}

/* Cropper JS rounded avatar */
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

/* required asteriks */
.label-required:after {
  content: " *";
  @apply text-red-600;
}

/* start Tom select */

.ts-control,
.ts-control input,
.ts-dropdown {
  @apply text-base;
}

.ts-wrapper.single .ts-control {
  box-shadow: none;
  @apply bg-white;
  background-image: none;
  background-repeat: no-repeat;
}

.ts-wrapper.single .ts-control,
.ts-control {
  @apply border-primary-200;
  @apply rounded;
  @apply border-0;
  @apply py-[0.5rem];
  @apply px-2;
  @apply leading-6;
}

input.answer-button + label {
  @apply bg-none;
  @apply text-primary-600;
}

input.answer-button:checked + label {
  @apply bg-primary-500;
  @apply text-white;
}

input.answer-button + label .checked {
  @apply hidden
}

input.answer-button:checked + label .unchecked {
  @apply hidden
}
input.answer-button:checked + label .checked {
  @apply inline-block
}

button[disabled] {
  @apply opacity-50;
  @apply cursor-not-allowed;
}

.avatar-edit-absolute-position {
  position: absolute;
  right: -5px;
  bottom: -5px;
}
