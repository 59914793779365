.range-slider {
  @apply w-full;
  @apply outline-none;
  @apply appearance-none;
  //@apply bg-primary-300;
  @apply bg-transparent;
  @apply rounded-full;
  height: 6px;

  &::-webkit-slider-thumb {
    @apply rounded-full;
    @apply bg-white;
    //@apply border-3;
    border-width: 3px;
    @apply border-solid;
    @apply border-primary-900;
    @apply appearance-none;
    @apply cursor-pointer;
    width: 16px;
    height: 16px;
  }

  &-previous {
    &::-webkit-slider-thumb {
      @apply border-primary-200;
    }
  }
}
